<template>
  <div class="page-content">
    <div class="container-fluid">
      <Breadcrumb
        :pgTitle="$t('nav.settings')"
        :pgIcon="'bx bx-wrench'"
        :refs="refs"
        :pgCurrent="pgCurrent"
        :addNew="false"
      >
      </Breadcrumb>

      <div class="row">
        <Loading v-if="pgLoading"> </Loading>

        <div v-if="!pgLoading" class="col-8 col-sm-12">
          <FormElements
            :label="$t('view.setting_info')"
            :labelTitle="$t('view.title')"
            :labelBody="$t('view.body')"
            :labelAddress="$t('view.address')"
            :hasTitle="true"
            :title_ar="row.title_ar"
            :title_en="row.title_en"
            :hasBody="true"
            :body_ar="row.body_ar"
            :body_en="row.body_en"
            :hasAddress="true"
            :address_ar="row.address_ar"
            :address_en="row.address_en"
            v-on:formChange="handleFormChange"
          >
          </FormElements>

          <FormSingleLang
            :labelEmail="$t('view.email')"
            :hasEmail="true"
            :email="row.email"
            :labelCountryCode="$t('view.country_code')"
            :hasCountryCode="true"
            :country_code="row.country_code"
            :labelMobile="$t('view.mobile')"
            :hasMobile="true"
            :mobile="row.mobile"
            v-on:formChange="handleDetailChange"
          >
          </FormSingleLang>
        </div>

        <div v-if="!pgLoading" class="col-4 col-sm-12">
          <CardImage
            :labelImage="$t('view.image')"
            :image_base64="row.image_base64"
            :image_preview="row.image_preview"
            v-on:imageChange="handleImageChange"
          >
          </CardImage>

          <CardImage
            :labelImage="$t('view.favicon')"
            :image_base64="row.favicon_base64"
            :image_preview="row.favicon_preview"
            v-on:imageChange="handleFaviconChange"
          >
          </CardImage>
        </div>
      </div>

      <Buttons
        v-if="!pgLoading"
        :btnLoading="btnLoading"
        :btnCurrent="btnCurrent"
        v-on:submitClicked="handelSubmit"
        v-on:cancelClicked="handleCancel"
      >
      </Buttons>
    </div>
  </div>
</template>

<script>
import iziToast from "izitoast";
import FormElements from "@/components/FormElements";
import CardImage from "@/components/CardImage";
import Breadcrumb from "@/components/Breadcrumb";
import Loading from "@/components/Loading";
import Buttons from "@/components/Buttons";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import FormSingleLang from "@/components/FormSingleLang";

export default {
  name: "EditOrNew",
  components: {
    FormElements,
    Breadcrumb,
    Loading,
    Buttons,
    CardImage,
    FormSingleLang,
  },
  data() {
    return {
      // auth
      auth: {
        role: "",
        id_token: "",
      },

      // row
      row: {
        title_ar: "",
        title_en: "",

        email: "",
        mobile: "",
        country_code: "",

        address_ar: "",
        address_en: "",

        body_ar: "",
        body_en: "",

        favicon_base64: "",
        favicon_preview: "",
        image_base64: "",
        image_preview: "",
      },

      msgCurrent: "Updated",
      btnCurrent: "Update",
      methodType: "POST",
      pgLoading: true,
      pgCurrent: "Edit",
      btnLoading: false,

      refs: "settings",
    };
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem("id_token")) {
      this.auth.id_token = localStorage.getItem("id_token");
    }
    if (localStorage.getItem("role_key")) {
      this.auth.role = localStorage.getItem("role_key");
    }
    setCurrentPageTitle(this.$t("nav.settings"), "bx bx-wrench");

    this.fetchRow();
  },
  methods: {
    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const options = {
        url: this.refs,
        method: "GET",
        data: {},
      };
      this.axios(options)
        .then((res) => {
          this.pgLoading = false;

          this.row.title_ar = res.data.data.ar.title;
          this.row.title_en = res.data.data.en.title;

          this.row.email = res.data.data.email;
          this.row.mobile = res.data.data.mobile;
          this.row.country_code = res.data.data.country_code;

          this.row.address_ar = res.data.data.ar.address;
          this.row.address_en = res.data.data.en.address;

          this.row.body_ar = res.data.data.ar.body;
          this.row.body_en = res.data.data.en.body;

          this.row.image_preview = res.data.data.image
            ? res.data.data.image.url
            : "";

          this.row.image_base64 = res.data.data.image
            ? res.data.data.image.url
            : "";

          this.row.favicon_base64 = res.data.data.favicon
            ? res.data.data.favicon.url
            : "";
          this.row.favicon_preview = res.data.data.favicon
            ? res.data.data.favicon.url
            : "";
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        "X-Requested-With": "XMLHttpRequest", // security to prevent CSRF attacks
        Authorization: `Bearer ` + this.auth.id_token,
      };
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      const options = {
        url: this.$route.params.id
          ? this.refs + "/" + this.$route.params.id
          : this.refs,
        method: this.methodType,
        data: {
          ar: {
            title: this.row.title_ar,
            body: this.row.body_ar,
            address: this.row.address_ar,
          },

          en: {
            title: this.row.title_en,
            body: this.row.body_en,
            address: this.row.address_en,
          },

          image_base64: this.row.image_base64,
          favicon_base64: this.row.favicon_base64,
          email: this.row.email,
          mobile: this.row.mobile,
          country_code: this.row.country_code,
        },
      };
      this.axios(options, config)
        .then(() => {
          this.btnLoading = false;
          iziToast.success({
            icon: "bx bx-wink-smile",
            title: "",
            message:
              this.btnCurrent == "Update"
                ? "تم التعديل بنجاح"
                : "تم الإضافة بنجاح",
          });

          window.location.reload();
        })
        .catch((err) => {
          // 403 Forbidden
          if (err.response && err.response.status == 401) {
            this.clearLocalStorage();
            this.$router.push({ name: "login" });
          } else if (err.response && err.response.status == 403) {
            this.$router.push({ name: "forbidden" });
          } else {
            this.btnLoading = false;
            iziToast.warning({
              icon: "bx bx-angry",
              title: "",
              message: err.response ? err.response.data.message : "" + err,
            });
          }
        });
    },

    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem("id_token");
      localStorage.removeItem("avatar");
      localStorage.removeItem("username");
      localStorage.removeItem("encrypt_id");
      localStorage.removeItem("role");
    },

    // Cancel
    handleCancel() {
      if (confirm("هل انت متأكد؟")) {
        this.$router.push({ name: this.refs });
      }
    },

    // handel Changes from child components
    handleFormChange(event) {
      this.row.title_ar = event.title_ar;
      this.row.title_en = event.title_en;

      this.row.body_ar = event.body_ar;
      this.row.body_en = event.body_en;

      this.row.address_ar = event.address_ar;
      this.row.address_en = event.address_en;
    },

    // handel Changes from child components
    handleDetailChange(event) {
      console.log(event);
      this.row.email = event.email;
      this.row.mobile = event.mobile;
      this.row.country_code = event.mobile_country_code;
    },
    handleImageChange(event) {
      this.row.image_base64 = event.image_base64;
    },
    handleFaviconChange(event) {
      this.row.favicon_base64 = event.image_base64;
    },

    handleSortChange(event) {
      this.row.sort = event.sort;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },
  },
};
</script>
